import "../styles/index.scss";

(() => {
  let activeReference = 1;
  let referencesLength = 1;
  let toggleAnimationDistance = 0;
  let sections = [];
  let scrollToTopBtn;
  let db = firebase.firestore();

  document.addEventListener("DOMContentLoaded", () => {
    window.onscroll = () => {
      if (window.scrollY > toggleAnimationDistance) {
        if (!scrollToTopBtn.classList.contains('animate')) {
          scrollToTopBtn.classList.add('animate');
        }
      } else if (scrollToTopBtn.classList.contains('animate')) {
        scrollToTopBtn.classList.remove('animate');
      }

      for (let section of sections) {
        if (section[1].classList.contains("animate")) break;
        else if (window.scrollY > section[0] - toggleAnimationDistance)
          section[1].classList.add("animate");
      }
    };
    window.onresize = calculateDistances;
    calculateDistances();
    document.querySelector("#references section .card-layout:first-of-type").classList.add("active");
    referencesLength = document.querySelectorAll("#references section .card-layout").length;
    scrollToTopBtn = document.getElementById("scroll-to-top-btn");
    document.getElementById(
      "special-offer-since"
    ).textContent = `01.${((new Date()).getMonth() + 1).toString().padStart(2, 0)}`;
    document.getElementById(
      "special-offer-till"
    ).textContent = '15.' + ((((new Date()).getMonth() + 2) % 12 + 12) % 12).toString().padStart(2, 0);
  });

  /**
   * Navigates to a position on the page by hash and handles the source descriptor value to provide the desired behavior.
   */
  window.navigateTo = function (hash, source) {
    location.hash = hash;
    if (location.hash == "#order-form") {
      document.getElementById(
        "order-form-title"
      ).textContent = getOrderFormTitle(source);
      document.getElementById(
        "submit-btn"
      ).textContent = getSubmitBtnTitle(source);
    }
  }

  /**
   * Clears the location hash without changing page view position.
   */
  window.navigateClear = function () {
    location.hash = "_plain_";
    let uri = location.toString();
    history.replaceState({}, document.title, uri.substring(0, uri.indexOf("#")));
  }

  window.switchReference = function (steps) {
    document.querySelector(`#references section .card-layout:nth-of-type(${activeReference})`)
      .classList.remove("active");
    activeReference = (activeReference - 1 + steps + referencesLength) % referencesLength + 1;
    document.querySelector(`#references section .card-layout:nth-of-type(${activeReference})`)
      .classList.add("active");
  }

  /**
   * Validates and submits the order form.
   */
  window.submitOrderForm = function (event) {
    event.preventDefault();

    let isValid = true;
    let params = {};
    let form = event.target;
    let button = event.target.querySelector("button");
    let alert = event.target.querySelector("[alert]");

    Array.from(form.elements).forEach((el) => {
      if (!el.checkValidity()) {
        el.classList.add("error");
        isValid = false;

        return;
      }

      params[el.name] = el.value;
      el.classList.remove("error");
    });

    if (!isValid) {
      alert.textContent =
        "Заполните все поля с *";
      form.classList.add("validation-error");

      return;
    }

    button.setAttribute("disabled", "true");
    form.classList.remove("validation-error");
    alert.textContent = "";
    db.collection("orders").add({
      name: params.name,
      telephone: params.telephone,
      comment: params.comment,
      time: firebase.firestore.FieldValue.serverTimestamp(),
      clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      formTitle: document.getElementById('order-form-title').innerText,
      orderStatus: 'новый'
    })
      .then(() => {
        button.removeAttribute("disabled");
        location.replace("#order-success");
        ym(66504682, 'reachGoal', 'orderCallFormSubmitted');
        // VK.Goal('conversion');
      })
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            source: "order-form",
          }
        });
        button.removeAttribute("disabled");
        alert.textContent = "К сожалению, нам сейчас не удается отправить Ваш запрос. Но Вы можете воспользоваться номером, указанным ниже, чтобы связаться с Анной Васильевной самостоятельно.";
        form.classList.add("validation-error");
        console.error("Error adding document: ", error);
      })
      .then(() => {
        Array.from(form.elements).forEach((el) => el.value = '');
      });
  }

  /**
   * Calculates distances for triggering onscroll animations on elements.
   */
  function calculateDistances() {
    toggleAnimationDistance = window.innerHeight * 0.85;
    sections = Array.from(document.getElementsByTagName("section"))
      .map((section) => {
        return [
          section.getBoundingClientRect().top + window.scrollY,
          section,
        ];
      })
      .reverse();
  };

  /**
   * Returns a title for the order form according to the source that triggered the form opening.
   */
  function getOrderFormTitle(source) {
    switch (source) {
      case "program-help":
        return "Получить бесплатную консультацию по телефону:";
      // case "program-basic":
      //   return 'Узнать детали программы "Для начинающих" у преподавателя:';
      case "program-individual":
        return 'Узнать о программах индивидуальных занятий у преподавателя:';
      // case "program-oge":
      //   return 'Узнать детали программы "Подготовка к ОГЭ" у преподавателя:';
      case "program-group":
        return 'Узнать подробности о занятиях в мини-группах у преподавателя:';
      case "special-offer":
        return "Записаться на бесплатное занятие и получить оценку своих знаний:";
      default:
        return "Получить информацию о программах и расписании занятий по телефону:";
    }
  }

  /**
   * Returns a title for the order form's submit button according to the source that triggered the form opening.
   */
  function getSubmitBtnTitle(source) {
    switch (source) {
      case "program-help":
        return "ПЕРЕЗВОНИТЕ МНЕ";
      case "program-individual":
      case "program-group":
        return "УЗНАТЬ ДЕТАЛИ";
      case "special-offer":
        return "ЗАПИСАТЬСЯ НА ЗАНЯТИЕ";
      default:
        return "ПОЛУЧИТЬ ИНФОРМАЦИЮ";
    }
  }
})();
